var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    [
      _c("Header", {
        attrs: {
          title: _vm.txt.pageTitle,
          subtitle: _vm.txt.pageSubtitle,
          itemsBreadCrumb: _vm.itemsBreadCrumb,
          hasAddButton: false,
          hasUpdateInfo: false,
        },
      }),
      _c(
        "v-card",
        { staticClass: "px-0 pt-0 pb-0" },
        [
          _c(
            "v-card-title",
            [
              _c("TableSearch", {
                attrs: {
                  id: "search",
                  label: _vm.txt.tableSearchLabel,
                  disabled: _vm.loadingTable,
                },
                on: { input: _vm.onInputSearch },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _c("v-spacer"),
              _c("FilterEncapsulated", {
                staticClass: "mr-4",
                attrs: {
                  showTitle: false,
                  showSwitch: false,
                  disabled: _vm.isFiltersDisabled,
                },
                on: {
                  applyFilter: _vm.applyFilter,
                  clearFilters: _vm.clearFilter,
                  closeFilterDialog: _vm.closeFilterDialog,
                },
                scopedSlots: _vm._u([
                  {
                    key: "filters",
                    fn: function () {
                      return [
                        _c("SelectSimple", {
                          staticClass: "mb-4",
                          attrs: {
                            disabled: _vm.isFiltersDisabled,
                            hasSelectAll: false,
                            id: "selecSituation",
                            label: _vm.txt.situation,
                            items: _vm.selectSituation,
                          },
                          model: {
                            value: _vm.filters.situation,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "situation", $$v)
                            },
                            expression: "filters.situation",
                          },
                        }),
                        _c("SelectSimple", {
                          staticClass: "mb-4",
                          attrs: {
                            disabled: _vm.isFiltersDisabled,
                            hasSelectAll: false,
                            id: "selectType",
                            label: _vm.txt.typeNature,
                            items: _vm.selectType,
                          },
                          model: {
                            value: _vm.filters.typeNature,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "typeNature", $$v)
                            },
                            expression: "filters.typeNature",
                          },
                        }),
                        _c("SelectSimple", {
                          staticClass: "mb-4",
                          attrs: {
                            disabled: _vm.isFiltersDisabled,
                            hasSelectAll: false,
                            id: "selectCp",
                            label: _vm.txt.cp,
                            items: _vm.selectCp,
                          },
                          model: {
                            value: _vm.filters.cp,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "cp", $$v)
                            },
                            expression: "filters.cp",
                          },
                        }),
                        _c("SelectSimple", {
                          staticClass: "mb-4",
                          attrs: {
                            disabled: _vm.isFiltersDisabled,
                            hasSelectAll: false,
                            id: "selectIrrf",
                            label: _vm.txt.irrf,
                            items: _vm.selectIrrf,
                          },
                          model: {
                            value: _vm.filters.irrf,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "irrf", $$v)
                            },
                            expression: "filters.irrf",
                          },
                        }),
                        _c("SelectSimple", {
                          staticClass: "mb-4",
                          attrs: {
                            disabled: _vm.isFiltersDisabled,
                            hasSelectAll: false,
                            id: "selectFgts",
                            label: _vm.txt.fgts,
                            items: _vm.selectFgts,
                          },
                          model: {
                            value: _vm.filters.fgts,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "fgts", $$v)
                            },
                            expression: "filters.fgts",
                          },
                        }),
                        _c("SelectSimple", {
                          staticClass: "mb-4",
                          attrs: {
                            disabled: _vm.isFiltersDisabled,
                            hasSelectAll: false,
                            id: "selectCPRP",
                            label: _vm.txt.cprp,
                            items: _vm.selectCPRP,
                          },
                          model: {
                            value: _vm.filters.cprp,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "cprp", $$v)
                            },
                            expression: "filters.cprp",
                          },
                        }),
                        _c("SelectSimple", {
                          staticClass: "mb-4",
                          attrs: {
                            disabled: _vm.isFiltersDisabled,
                            hasSelectAll: false,
                            id: "selectUsesTetoRemun",
                            label: _vm.txt.usesTetoRemun,
                            items: _vm.selectUsesTetoRemun,
                          },
                          model: {
                            value: _vm.filters.usesTetoRemun,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "usesTetoRemun", $$v)
                            },
                            expression: "filters.usesTetoRemun",
                          },
                        }),
                        _c("AGDatePickerRange", {
                          staticClass: "mb-4",
                          attrs: {
                            id: "selecUpdatedAt",
                            label: _vm.txt.updatedAt,
                            disabled: false,
                          },
                          model: {
                            value: _vm.filters.updatedAt,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "updatedAt", $$v)
                            },
                            expression: "filters.updatedAt",
                          },
                        }),
                        _c("SelectSimple", {
                          staticClass: "mb-4",
                          attrs: {
                            disabled: _vm.isFiltersDisabled,
                            hasSelectAll: false,
                            id: "selecUpdateBy",
                            label: _vm.txt.updateBy,
                            items: _vm.selectUpdateBy,
                          },
                          model: {
                            value: _vm.filters.updateBy,
                            callback: function ($$v) {
                              _vm.$set(_vm.filters, "updateBy", $$v)
                            },
                            expression: "filters.updateBy",
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
              _c("ExportButton", {
                attrs: { disabled: _vm.loadingTable },
                on: { click: _vm.onExportIncidenceNature },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "py-0 pb-0", attrs: { cols: "12", sm: "12" } },
            [
              [
                _c(
                  "div",
                  { staticClass: "text-right", attrs: { id: "showChip" } },
                  [
                    _vm.showChipSituation
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.removeChip("situation")
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.filters.situation) + " ")]
                        )
                      : _vm._e(),
                    _vm.showChipTypeNature
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.removeChip("typeNature")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " Tipo: " +
                                _vm._s(
                                  _vm.searchCodeName(
                                    _vm.filters.typeNature,
                                    _vm.selectType
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showChipCp
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.removeChip("cp")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " INSS: " +
                                _vm._s(
                                  _vm.searchCodeName(
                                    _vm.filters.cp,
                                    _vm.selectCp
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showChipIrrf
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.removeChip("irrf")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " IRRF: " +
                                _vm._s(
                                  _vm.searchCodeName(
                                    _vm.filters.irrf,
                                    _vm.selectIrrf
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showChipFgts
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.removeChip("fgts")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " FGTS: " +
                                _vm._s(
                                  _vm.searchCodeName(
                                    _vm.filters.fgts,
                                    _vm.selectFgts
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showChipCprp
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.removeChip("cprp")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " CPRP: " +
                                _vm._s(
                                  _vm.searchCodeName(
                                    _vm.filters.cprp,
                                    _vm.selectCPRP
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showChipUsesTetoRemun
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.removeChip("usesTetoRemun")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " Teto remuneratório: " +
                                _vm._s(_vm.filters.usesTetoRemun) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showChipUpdatedAt
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.removeChip("updatedAt")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.concatDate(
                                    _vm.filters.updatedAt,
                                    "Atualização: "
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.showChipUpdateBy
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.removeChip("updateBy")
                              },
                            },
                          },
                          [
                            _vm._v(
                              " Atualizado por: " +
                                _vm._s(_vm.searchName(_vm.filters.updateBy)) +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
          _c(
            "v-card-text",
            { staticClass: "px-0 pb-0" },
            [
              _c("TableIncidenceNature", {
                attrs: {
                  tableTitle: _vm.txt.tableTitle,
                  loadingTable: _vm.loadingTable,
                  headers: _vm.headers,
                  items: _vm.incidenceNatureList,
                  paginationFilter: _vm.incidenceNaturePagination,
                  totalItems: _vm.totalIncidenceNature,
                },
                on: { setPagination: _vm.setPagination },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }