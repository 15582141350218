<template>
  <v-main>
    <Header
      :title="txt.pageTitle"
      :subtitle="txt.pageSubtitle"
      :itemsBreadCrumb="itemsBreadCrumb"
      :hasAddButton="false"
      :hasUpdateInfo="false"
    ></Header>
    <v-card class="px-0 pt-0 pb-0">
      <v-card-title>
        <TableSearch
          id="search"
          :label="txt.tableSearchLabel"
          v-model="search"
          @input="onInputSearch"
          :disabled="loadingTable"
        ></TableSearch>
        <v-spacer></v-spacer>
        <FilterEncapsulated
          class="mr-4"
          :showTitle="false"
          :showSwitch="false"
          :disabled="isFiltersDisabled"
          @applyFilter="applyFilter"
          @clearFilters="clearFilter"
          @closeFilterDialog="closeFilterDialog"
        >
          <template v-slot:filters>
            <SelectSimple
              class="mb-4"
              :disabled="isFiltersDisabled"
              :hasSelectAll="false"
              id="selecSituation"
              :label="txt.situation"
              v-model="filters.situation"
              :items="selectSituation"
            />
            <SelectSimple
              class="mb-4"
              :disabled="isFiltersDisabled"
              :hasSelectAll="false"
              id="selectType"
              :label="txt.typeNature"
              v-model="filters.typeNature"
              :items="selectType"
            />
            <SelectSimple
              class="mb-4"
              :disabled="isFiltersDisabled"
              :hasSelectAll="false"
              id="selectCp"
              :label="txt.cp"
              v-model="filters.cp"
              :items="selectCp"
            />
            <SelectSimple
              class="mb-4"
              :disabled="isFiltersDisabled"
              :hasSelectAll="false"
              id="selectIrrf"
              :label="txt.irrf"
              v-model="filters.irrf"
              :items="selectIrrf"
            />
            <SelectSimple
              class="mb-4"
              :disabled="isFiltersDisabled"
              :hasSelectAll="false"
              id="selectFgts"
              :label="txt.fgts"
              v-model="filters.fgts"
              :items="selectFgts"
            />
            <SelectSimple
              class="mb-4"
              :disabled="isFiltersDisabled"
              :hasSelectAll="false"
              id="selectCPRP"
              :label="txt.cprp"
              v-model="filters.cprp"
              :items="selectCPRP"
            />
            <SelectSimple
              class="mb-4"
              :disabled="isFiltersDisabled"
              :hasSelectAll="false"
              id="selectUsesTetoRemun"
              :label="txt.usesTetoRemun"
              v-model="filters.usesTetoRemun"
              :items="selectUsesTetoRemun"
            />
            <AGDatePickerRange
              class="mb-4"
              id="selecUpdatedAt"
              :label="txt.updatedAt"
              v-model="filters.updatedAt"
              :disabled="false"
            />
            <SelectSimple
              class="mb-4"
              :disabled="isFiltersDisabled"
              :hasSelectAll="false"
              id="selecUpdateBy"
              :label="txt.updateBy"
              v-model="filters.updateBy"
              :items="selectUpdateBy"
            />
          </template>
        </FilterEncapsulated>

        <ExportButton @click="onExportIncidenceNature" :disabled="loadingTable"></ExportButton>
      </v-card-title>
      <v-col class="py-0 pb-0" cols="12" sm="12">
        <template>
          <div class="text-right" id="showChip">
            <v-chip v-if="showChipSituation" class="ma-1" close @click:close="removeChip('situation')">
              {{ filters.situation }}
            </v-chip>
            <v-chip v-if="showChipTypeNature" class="ma-1" close @click:close="removeChip('typeNature')">
              Tipo: {{ searchCodeName(filters.typeNature, selectType) }}
            </v-chip>
            <v-chip v-if="showChipCp" class="ma-1" close @click:close="removeChip('cp')">
              INSS: {{ searchCodeName(filters.cp, selectCp) }}
            </v-chip>
            <v-chip v-if="showChipIrrf" class="ma-1" close @click:close="removeChip('irrf')">
              IRRF: {{ searchCodeName(filters.irrf, selectIrrf) }}
            </v-chip>
            <v-chip v-if="showChipFgts" class="ma-1" close @click:close="removeChip('fgts')">
              FGTS: {{ searchCodeName(filters.fgts, selectFgts) }}
            </v-chip>
            <v-chip v-if="showChipCprp" class="ma-1" close @click:close="removeChip('cprp')">
              CPRP: {{ searchCodeName(filters.cprp, selectCPRP) }}
            </v-chip>
            <v-chip v-if="showChipUsesTetoRemun" class="ma-1" close @click:close="removeChip('usesTetoRemun')">
              Teto remuneratório: {{ filters.usesTetoRemun }}
            </v-chip>
            <v-chip v-if="showChipUpdatedAt" class="ma-1" close @click:close="removeChip('updatedAt')">
              {{ concatDate(filters.updatedAt, 'Atualização: ') }}
            </v-chip>
            <v-chip v-if="showChipUpdateBy" class="ma-1" close @click:close="removeChip('updateBy')">
              Atualizado por: {{ searchName(filters.updateBy) }}
            </v-chip>
          </div>
        </template>
      </v-col>
      <v-card-text class="px-0 pb-0">
        <TableIncidenceNature
          :tableTitle="txt.tableTitle"
          :loadingTable="loadingTable"
          :headers="headers"
          :items="incidenceNatureList"
          :paginationFilter="incidenceNaturePagination"
          :totalItems="totalIncidenceNature"
          @setPagination="setPagination"
        >
        </TableIncidenceNature>
      </v-card-text>
    </v-card>
  </v-main>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';
import { eventBus } from '@/main.js';
import { capitalize } from '@/utils/text.js';

export default {
  name: 'IncidenceNature',
  components: {
    Header: () => import('../../commons/Header.vue'),
    TableSearch: () => import('../../commons/TableSearch.vue'),
    FilterEncapsulated: () => import('../../commons/FilterEncapsulated.vue'),
    SelectSimple: () => import('../../commons/SelectSimple.vue'),
    AGDatePickerRange: () => import('../../commons/AGDatePickerRange.vue'),
    ExportButton: () => import('../../commons/ExportButton.vue'),
    TableIncidenceNature: () => import('./components/TableIncidenceNature.vue'),
  },
  data() {
    return {
      itemsBreadCrumb: [
        {
          title: 'Home',
          disabled: false,
          href: 'home',
        },
        {
          title: 'Configurações',
          disabled: true,
        },
        {
          title: 'e-Social',
          disabled: true,
        },
        {
          title: 'Parâmetros de análise ',
          disabled: false,
          href: '/cadastros-gerenciais/e-social/analysis-parameters',
        },
        {
          title: 'Incidências por Natureza',
          disabled: true,
          active: true,
        },
      ],
      txt: {
        tableTitle: 'Naturezas',
        situation: 'Situação',
        typeNature: 'Tipo',
        cp: 'CP',
        irrf: 'IRRF',
        fgts: 'FGTS',
        cprp: 'CPRP',
        usesTetoRemun: 'Teto Remuneratório',
        updatedAt: 'Período de atualização',
        updateBy: 'Atualizado por',
        pageTitle: 'Incidência por Natureza',
        pageSubtitle: 'Gerenciamento de padrões adotados pela equipe técnica',
        tableSearchLabel: 'Pesquisar por código nome ou descrição',
        msgToast:
          "<div class='row'><div class='col-8 ml-0 mr-0 pl-0 pr-0 ' style='text-align: left;'>Sua solicitação de exportação foi recebida com sucesso! </br> Faça o download assim que o arquivo estiver disponível no menu 'Analytics' na opção 'Downloads' </div> <div class='col-4 ml-0 mr-0 pt-5 mt-4 pl-0 pr-0'><a href='/documents/download' style='text-decoration:none;'>Clique aqui para acessar</a></div></div>",
      },
      search: '',
      filters: {
        situation: '',
        typeNature: '',
        cp: '',
        irrf: '',
        fgts: '',
        cprp: '',
        usesTetoRemun: '',
        updatedAt: [],
        updateBy: '',
      },
      toast: {
        message: '',
        type: '',
        show: false,
        topToast: 0,
      },
      headers: [
        { text: 'Código', align: 'start', value: 'rubricNatureCode', width: '78px', sortable: false },
        { text: 'Nome', align: 'start', value: 'rubricNatureName', width: '318px', sortable: false },
        { text: 'Situação', align: 'start', value: 'rubricNatureSituation', width: '89px', sortable: false },
        { text: 'Tipo', align: 'start', value: 'rubricTypeCode', width: '61px', sortable: false },
        { text: 'CP', align: 'start', value: 'inssIncidenceCode', width: '51px', sortable: false },
        { text: 'IRRF', align: 'start', value: 'irrfIncidenceCode', width: '63px', sortable: false },
        { text: 'FGTS', align: 'start', value: 'fgtsIncidenceCode', width: '67px', sortable: false },
        { text: 'CPRP', align: 'start', value: 'cprpIncidenceCode', width: '69px', sortable: false },
        { text: 'Teto remuneratório', align: 'start', value: 'usesTetoRemun', width: '156px', sortable: false },
        { text: 'Descrição', align: 'start', value: 'rubricNatureDesc', width: '389px', sortable: false },
        { text: 'Início', align: 'start', value: 'rubricNatureStart', width: '94px', sortable: false },
        { text: 'Término', align: 'start', value: 'rubricNatureEnd', width: '119px', sortable: false },
        { text: 'Alterado por', value: 'user', width: '149px', sortable: false },
        { text: 'Atualização', value: 'updatedAt', width: '206px', sortable: false },
        { text: 'Ações', align: 'start', value: 'actions', width: '88px', sortable: false },
      ],
      showChipSituation: false,
      showChipTypeNature: false,
      showChipCp: false,
      showChipIrrf: false,
      showChipFgts: false,
      showChipCprp: false,
      showChipUsesTetoRemun: false,
      showChipUpdatedAt: false,
      showChipUpdateBy: false,
    };
  },
  computed: {
    ...mapState('usuarios', { users: (state) => state.users }),
    ...mapGetters({
      loadingIncidenceNatureList: 'cadastrosGerenciais/analysisParametersModule/getLoadingIncidenceNatureList',
      loadingFilters: 'cadastrosGerenciais/analysisParametersModule/getLoadingFilters',
      filtersSaved: 'cadastrosGerenciais/analysisParametersModule/getFiltersSaved',
      incidenceNatureList: 'cadastrosGerenciais/analysisParametersModule/getIncidenceNatureList',
      incidenceNaturePagination: 'cadastrosGerenciais/analysisParametersModule/getIncidenceNaturePagination',
      totalIncidenceNature: 'cadastrosGerenciais/analysisParametersModule/getTotalIncidenceNature',
      selectSituation: 'cadastrosGerenciais/analysisParametersModule/getSituationList',
      selectType: 'cadastrosGerenciais/analysisParametersModule/getNatureTypeList',
      selectCp: 'cadastrosGerenciais/analysisParametersModule/getIncidenceINSSList',
      selectIrrf: 'cadastrosGerenciais/analysisParametersModule/getIncidenceIRRFList',
      selectFgts: 'cadastrosGerenciais/analysisParametersModule/getIncidenceFGTSList',
      selectCPRP: 'cadastrosGerenciais/analysisParametersModule/getIncidenceCPRPList',
      selectUsesTetoRemun: 'cadastrosGerenciais/analysisParametersModule/getUsesTetoRemunList',
      scheduleReport: 'cadastrosGerenciais/analysisParametersModule/getScheduleReport',
    }),
    loadingTable() {
      return this.loadingIncidenceNatureList;
    },
    loadingAllFilters() {
      return this.loadingFilters;
    },
    isFiltersDisabled() {
      return this.loadingAllFilters;
    },
    selectUpdateBy() {
      let comboUsers = [];
      this.users.forEach((user) => {
        user.itemTitle = `${user.firstName} ${user.lastName}`;
        user.itemId = user.username;
        user.value = user.username;
        comboUsers.push(user);
      });
      return comboUsers;
    },
  },
  created() {
    this.init();
    this.getUsers();
  },
  methods: {
    capitalize,
    ...mapActions('usuarios', ['getUsers']),
    ...mapActions('cadastrosGerenciais/analysisParametersModule', [
      'init',
      'setIncidenceNaturePagination',
      'searchIncidenceNature',
      'listIncidences',
      'applyFilters',
      'clearFilters',
      'exportIncidenceNature',
    ]),
    searchCodeName(code, incidenceList) {
      const incidence = incidenceList.find((incidence) => incidence.code === code);
      return incidence ? incidence.codeName : '';
    },
    searchName(username) {
      const user = this.users.find((user) => user.username === username);
      return user ? capitalize(`${user.firstName} ${user.lastName}`) : '';
    },
    setPagination(pagination) {
      this.setIncidenceNaturePagination({ pagination: pagination, search: this.search });
    },
    onInputSearch(search) {
      this.searchIncidenceNature(search);
    },
    applyFilter() {
      this.showChips();
      this.applyFilters({
        filters: this.filters,
        search: this.search,
      });
    },
    showChips() {
      this.showChipSituation = this.filters.situation !== '';
      this.showChipTypeNature = this.filters.typeNature !== '';
      this.showChipCp = this.filters.cp !== '';
      this.showChipIrrf = this.filters.irrf !== '';
      this.showChipFgts = this.filters.fgts !== '';
      this.showChipCprp = this.filters.cprp !== '';
      this.showChipUsesTetoRemun = this.filters.usesTetoRemun !== '';
      this.showChipUpdatedAt = this.filters.updatedAt.length > 0;
      this.showChipUpdateBy = this.filters.updateBy !== '';
    },
    removeChip(filterKey) {
      if (this.filters.hasOwnProperty(filterKey)) {
        if (typeof this.filters[filterKey] === 'object') {
          this.filters[filterKey] = { codeName: '' };
        } else {
          this.filters[filterKey] = '';
        }
        this[`showChip${filterKey.charAt(0).toUpperCase() + filterKey.slice(1)}`] = false;
      }
      this.filters[filterKey] = '';
      this.applyFilter();
    },
    concatDate(dateArray, label) {
      if (dateArray.length === 2) {
        const startDate = dateArray[0];
        const endDate = dateArray[1];
        const startMonthYear = startDate.split('-');
        const endMonthYear = endDate.split('-');
        return `${label} ${startMonthYear[2]}/${startMonthYear[1]}/${startMonthYear[0]} a ${endMonthYear[2]}/${endMonthYear[1]}/${endMonthYear[0]}`;
      } else {
        return 'Datas inválidas';
      }
    },
    clearFilter() {
      this.clearFilters();
    },
    closeFilterDialog() {
      this.filters = cloneDeep(this.filtersSaved);
    },
    async onExportIncidenceNature() {
      await this.exportIncidenceNature();

      if (this.scheduleReport) {
        this.showToast(this.txt.msgToast, 'success', 78);
      } else {
        this.showToast('Erro ao exportar relatório.', 'error', 78);
      }
    },
    showToast(message, type, topToast) {
      this.toast.show = true;
      this.toast.type = type;
      this.toast.message = message;
      this.toast.topToast = topToast;
      eventBus.$emit('openToast', this.toast);
    },
  },
};
</script>

<style lang="scss" scoped>
@import './src/design/components/table.scss';
</style>
